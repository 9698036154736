.ball {
  height: 6px;
  width: 6px;
  border-radius: 100px;
  background-color: #2136d4;
  animation: spinBall 1s ease-in-out infinite;
  box-shadow: inset 0 0 2px #fff, inset 0px 0 2px #2136d4;
}

@keyframes spinBall {
  0% {
    transform: scale(0.9);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.9);
  }
}

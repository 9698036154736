.ebo-sidebar-ul {
  max-width: 100vw;
  width: 25rem;
}
.main-heading {
  margin: 8px 0px;
}
.sub-heading {
  font-size: 13px;
}
.ebo-logo {
  width: 80px;
  margin-left: 6px;
}
.header-container {
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: 23px;
  padding: 10px 23px;
}

.cross-image {
  margin-top: 10px;
  cursor: pointer;
  width: 15px;
}

@media screen and (max-width: 768px) {
  .ebo-sidebar-ul {
    width: 100vw;
  }
}

.bg-container {
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
}
.main-container {
  padding: 0px;
}

.icon {
  font-size: 27px;
  cursor: pointer;
}
.logo {
  width: 70px;
  margin-left: 20px;
  user-select: none;
}
.register-heading {
  font-size: 20px;
  font-weight: 600;
  font-family: "Roboto";
  margin-bottom: 26px;
}
.register-desc {
  font-size: 14px;
  font-family: "Roboto";
  margin-bottom: 5px;
  color: gray;
}
.line {
  width: 67px;
  border-width: 2px;
  border-color: #2136d4;
}
.select-options {
  border-width: 0px 0px 1px 0px;
  border-color: #d9d9d9;
  outline: none;
  font-size: 16px;
  font-family: "Roboto";
  color: #171717;
  width: 50px;
  background-color: transparent;
  padding-bottom: 8px;
}
.mobile-container {
  display: flex;
  margin-top: 13px;
}
.input-box-phone {
  margin-left: 20px;
  width: 30%;
}
.input-box-name {
  width: 100%;
  color: #323232;
}
.date-of-birth {
  height: 33px;
  width: 57px;
  margin-left: 8px;
  color: #b7b1b1;
  background-color: transparent;
  border-width: 0px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-repeat: no-repeat;
  background-position: 88% center;
  background-size: 10px; /* Adjust the size of the arrow icon as needed */
  /* padding-right: 25px; */
}
.dob-text {
  font-family: "Roboto";
  font-size: 16px;
  color: #b7b1b1;
  margin-right: 6px;
}

.year {
  width: 65px;
  color: #b7b1b1;
}
.referral-code {
  margin-top: 15px;
  margin-bottom: 18px;
}

.updates-text {
  font-family: "Roboto";
  font-size: 12px;
  color: #979797;
  letter-spacing: 1px;
  margin-bottom: 16px;
}
.updates-checkboxes {
  display: flex;
  padding-left: 4px;
  margin-bottom: 16px;
}
.each-box {
  margin-right: 30px;
  display: flex;
  align-items: center;
}

.label {
  font-family: "Roboto";
  font-size: 14px;
  color: #979797;
}

@media (min-width: 768px) {
  .bg-container {
    align-items: center;
  }
  .main-container {
    border-width: 1px;
    border-color: gray;
    border-radius: 10px;
    border-style: solid;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  }
}

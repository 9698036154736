input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.PhoneInput > input {
  outline: none;
  padding: 0 0.25rem;
  font-size: 17px;
  border-left: 1px solid rgba(0, 0, 0, 0.7);
}
.PhoneInput > input:disabled {
  background-color: white;
}

@media screen and (max-width: 340px) {
  #form-container {
    padding: 0 1.8rem 0 1rem;
  }
}

@media screen and (max-width: 376px) {
  #form-container {
    padding: 0 1.5rem;
  }
}
@media screen and (max-width: 426px) {
  #form-container {
    padding: 0 1.5rem;
  }
}

::-webkit-datetime-edit-text {
  color: rgba(0, 0, 0, 0.7);
  text-transform: uppercase;
}
::-webkit-datetime-edit-month-field {
  color: rgba(0, 0, 0, 0.7);
  text-transform: uppercase;
}
::-webkit-datetime-edit-day-field {
  color: rgba(0, 0, 0, 0.7);
  text-transform: uppercase;
}
::-webkit-datetime-edit-year-field {
  color: rgba(0, 0, 0, 0.7);
  text-transform: uppercase;
}
::-webkit-calendar-picker-indicator {
  color: rgba(204, 204, 204, 0);
  cursor: pointer;
}
